// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
//@import "~socicon/css/socicon.css";
//@import "~@fortawesome/fontawesome-free/css/all.min.css";
//@import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
//@import "./_metronic/_assets/plugins/flaticon/flaticon.css";
//@import "./_metronic/_assets/plugins/flaticon2/flaticon.css";


@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@media print {
  body {background-color: white !important}
  #main,#root {position:relative; padding:0; height:1px; overflow:visible; margin: 0; visibility: hidden}
  #printarea {position:absolute; width:100%; top:-150px;left:0; padding:0; margin:-1px; background-color: white; z-index: 1000; visibility: visible}
  #notVisible {visibility: hidden; display: none !important;}
  .notVisible {visibility: hidden; display: none !important;}
  #printarea #onlyPrint { display: block !important; width: 100%; }

  td,th {font-size: 9px; padding: 0 0.5rem !important;}
  div {font-size: 9px}
  a {color: black}
  #main .tableFixOnlyHead {display: block; overflow-y: visible;height: auto; }

  #main .tableFixOnlyHead #printarea {top:-100px}
  #printarea.register td,th,table,tr,div {font-size: 12px; color: black; border-color: black !important}
  .tableFixOnlyHead table  { border-collapse: initial}
}
//@page {
//  size: landscape;
//}
@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

//фиксит табы без враппера
.tab-content {
  background-color: transparent;
}
.nav-tabs .nav-item .tab-err.nav-link  {
  border: 1px solid #fd397a;
  color: #fd397a;
}
.nav-tabs .nav-item .tab-err.nav-link.active  {
  background-color: #fd397a ;
  color: white;
}

.qr-some .block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.95;
  filter: alpha(opacity=95);
  background-color: white;
}

.photo-select-item:hover button, .photo-select-item:hover a {
  display: block;
}

.photo-select-item button, .photo-select-item a {
  display: none;
}

.photo-select-item {
  position: relative;
}

.photo-select-item .delete {
  position: absolute;
  top: 5px;
  right: 5px
}

.photo-select-item .view {
  position: absolute;
  top: 5px;
  left: 5px
}

.loader {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-27%, -50%);

}

.parserSendMess {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.infoFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.breadcrumb {
  background-color: inherit;
}

.btn-sm {
  padding: 0.5rem;
  font-size: 0.78rem;
}

// links
a {
  text-decoration: none;
}

.btn.btn-sm [class*=" fa-"] {
  font-size: 0.8rem;
}

.ml-2 {
  margin-left: 5px;
}

.mb-2 {
  margin-bottom: 5px;
}

.serviceCard, .serviceCardError {
  padding: 1rem 25px;
  cursor: pointer;
}
.serviceCardError{
  background-color: rgba(253,57,122,0.13 );
}
.serviceCardError:hover{
  background-color: rgba(253,57,122,0.2 );
}
.serviceCard:hover{
  background-color: rgba(173, 181, 189, 0.16);
}
.creditCard {
  border-radius: 10px;
  min-width: 250px;
  max-width: 300px;
  padding: 10px;
  transition: 0.2s all;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}
.bank {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}
.name {
  margin-bottom: 10px;
}
.creditCard:hover {
  transform: translate(-1px,-1px);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.21);
}
.plus {
  margin: 0;
}
.modal-90w {
  max-width: 90%;
  width: 100%;
}
.absolutLabel{
  position: absolute;
  left: 5px;
  top: -9px;
  border-radius: 5px;
  background-color: white;
  padding: 0 5px;
  z-index: 1000;
  color: #798186;
}
.react-datepicker-popper {
  z-index: 10000 !important;
}
.clearIcon{
  position: absolute;
  top:35%;
  cursor: pointer;
  right: 10px;
  z-index: 100;
}

.modal-height{
  height: 90vh;
}
.pointer{
  cursor: pointer;
}

.tableFixHead          { overflow-y: auto; height: 100vh; }
.tableFixHead thead { position: sticky; top: 0; z-index: 1}
.tableFixHead tbody th { position: sticky; left: 0; }
/* Just common table stuff. Really. */
.tableFixHead table  { border-collapse: collapse; width: 100%; }
.tableFixHead th, td { padding: 8px 16px; }
.tableFixHead th     { background: #cfe2f3; }

.tableFixHead tbody td:last-child { background: #cfe2f3; }


.tableFixOnlyHead          { overflow-y: auto; height: 80vh; }
.tableFixOnlyHead thead { position: sticky; top: 0; z-index: 1; background-color: white}
.tableFixOnlyHead thead th{ padding: 0.5rem 0.5rem}
.tableFixOnlyHead table  { border-collapse: collapse; width: 100%; }


